import '../asset/components/Form.css';
import {useContext, useState, useEffect, Fragment} from "react";
import AppContext from "../context/AppContext";
import AsyncSelect from 'react-select/async';
import FormNote from "./FormNote";
import FormCategory from "./FormCategory";
import Modal from "react-modal";

function Form({scene, objectsInScene}) {
    const [errors, setErrors] = useState({emailCustomer: false, emailSeller: false, shop: false});
    const [emailCustomer, setEmailCustomer] = useState('');
    const [emailSeller, setEmailSeller] = useState('');
    const [shop, setShop] = useState(null);

    const [categories, setCategories] = useState([]);
    const {repository, sessionManager} = useContext(AppContext);

    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const sendEmail = () => {
        const regex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;

        const newErrors = {...errors};
        newErrors.emailCustomer = !emailCustomer.match(regex);
        newErrors.emailSeller = !emailSeller.match(regex);
        newErrors.shop = shop === null;

        setErrors(newErrors);

        if(Object.values(newErrors).every(error => error === false)){
            const form = document.querySelector('.form').innerHTML;
            const note = document.querySelector('.note').innerHTML;
            const data = `<div class="form-container">
                        <div class="form">
                        ${form}
                        </div>
                        <div class="note">
                        ${note}
                        </div>
                        </div>`;
            sessionManager.addDist({emailSeller, emailCustomer, shop})
            repository.sendEmail(emailSeller, emailCustomer, shop, data).then(
                () => setIsOpen(true)
            )
        }
    };

    useEffect(
        () => {
            repository.getCategories(scene)
                .then(
                    (categories) => {
                        setCategories(categories);
                    }
                )
        },
        []
    )

    const loadOptions = (query) =>
        new Promise(
            resolve =>
                repository.getShops(query)
                    .then(data => resolve(data))
        );

    return <Fragment>
        <section className="form">
            {
                categories.map(
                    category =>
                        <FormCategory key={category.id} category={category} objects={objectsInScene.filter(object => object.id_categorias === category.id)}/>
                )
            }
        </section>
        <section className="form">
            <div>
                <label className="form-label">Correo cliente *:</label>
                <div className="form-input-icon-wrap">
                    <input type="email" className="form-input-large" value={emailCustomer} onChange={e => setEmailCustomer(e.target.value)}/>
                    {
                        errors.emailCustomer ? <div className="form-input-error"></div> : ''
                    }
                </div>
            </div>
            <div>
                <label className="form-label">Correo asesor *:</label>
                <div className="form-input-icon-wrap">
                    <input type="email" className="form-input-large" value={emailSeller} onChange={e => setEmailSeller(e.target.value)}/>
                    {
                        errors.emailSeller ? <div className="form-input-error"></div> : ''
                    }
                </div>
            </div>
            <div>
                <label className="form-label">Punto de Venta *:</label>
                <div className="form-input-icon-wrap">
                    <AsyncSelect placeholder="Buscar punto de venta..." isClearable={true} cacheOptions defaultOptions loadOptions={loadOptions} onChange={e =>  setShop(e ? e.value : null)}/>
                    {
                        errors.shop ? <div className="form-input-error"></div> : ''
                    }
                </div>
            </div>
            <div style={{textAlign:'center', marginTop: '2rem'}}>
                <button className="form-button" onClick={sendEmail}>Enviar</button>
            </div>
        </section>
        <FormNote/>
        <Modal style={{content: {top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '240px', height: '140px'}}} isOpen={modalIsOpen} onRequestClose={closeModal}>
            <div className="form-email-confirmation">
                <p>¡La cotización fue enviada!</p>
                <br/>
                <button className="form-button" onClick={closeModal}>Cerrar</button>
            </div>
        </Modal>
    </Fragment>;
}

export default Form;
