class SessionRepository {

    APP_URL;
    API_URL;
    cache = {};

    constructor(api_url, app_url) {
        this.API_URL = api_url;
        this.APP_URL = app_url;
    }

    addSession(session) {
        return fetch(`${this.API_URL}/addsession`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(session)
        })
            .then(data => data.json())
    }

    updateSessionTime(session) {
        return fetch(`${this.API_URL}/updatesessiontime`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(session)
        })
            .then(data => data.json())
    }

    addSKU(data) {
        return fetch(`${this.API_URL}/addsku`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(data => data.json())
    }

    addDist(dist) {
        return fetch(`${this.API_URL}/adddist`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dist)
        })
            .then(data => data.json())
    }
}

export default SessionRepository;
